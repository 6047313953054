import type {
  FormControlProps,
  FormHelperTextProps,
  InputLabelProps,
  SelectProps as MUISelectProps,
  MenuItemProps,
  SxProps,
} from "@mui/material";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select as MUISelect,
  MenuItem,
} from "@mui/material";
import { forwardRef } from "react";

// Remove the any if a better solution is found
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectProps = Omit<MUISelectProps<any>, "variant" | "size" | "ref"> & {
  ariaLabel?: string;
  chip?: boolean;
  customOptions?: {
    value: string | number;
    label: string | JSX.Element;
    status?: string;
    icon?: JSX.Element;
    sx?: SxProps;
  }[];
  focused?: boolean;
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
  helperText?: string;
  inputLabelProps?: InputLabelProps;
  menuItemsProps?: MenuItemProps;
  notched?: boolean;
  options: { value: string | number; label: string | JSX.Element }[];
};

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      labelId,
      label,
      options,
      helperText,
      disabled,
      error,
      readOnly,
      required,
      fullWidth,
      focused,
      placeholder,
      notched,
      id,
      chip,
      sx,
      startAdornment,
      formControlProps,
      inputLabelProps,
      formHelperTextProps,
      menuItemsProps,
      inputProps: incomingInputProps,
      ariaLabel,
      customOptions,
      ...props
    },
    ref
  ) => {
    const inputProps: MUISelectProps["inputProps"] = {
      ...incomingInputProps,
    };

    if (ariaLabel) {
      inputProps["aria-label"] = ariaLabel;
    }

    if (readOnly) {
      inputProps["readOnly"] = true;
    }

    return (
      <FormControl
        ref={ref}
        size="small"
        disabled={disabled}
        error={error}
        required={required}
        fullWidth={fullWidth}
        focused={focused}
        sx={{ minWidth: 195, ...formControlProps?.sx }}
        {...formControlProps}
      >
        {label && (
          <InputLabel
            shrink={Boolean(placeholder && label) || inputLabelProps?.shrink}
            id={labelId}
            htmlFor={id}
            {...inputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <MUISelect
          notched={Boolean(placeholder && label) || inputLabelProps?.shrink || notched}
          size="small"
          variant="outlined"
          id={id}
          labelId={labelId}
          label={label}
          inputProps={inputProps}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              if (chip) {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }

              return selected.join(", ");
            }

            return options?.find((option) => option.value === selected)?.label ?? selected;
          }}
          startAdornment={
            startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>
          }
          sx={{
            "& .MuiSelect-select .notranslate::after": placeholder
              ? { content: `"${placeholder}"`, color: "grey.400" }
              : {},
            ...sx,
          }}
          {...props}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value} {...menuItemsProps}>
              {option.label}
            </MenuItem>
          ))}
          {customOptions?.map((customOption) => (
            <MenuItem
              key={customOption.value}
              value={customOption.value}
              {...menuItemsProps}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...customOption.sx,
              }}
            >
              {customOption.label}
              {customOption.icon}
            </MenuItem>
          ))}
        </MUISelect>
        {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
