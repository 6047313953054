import { LexicalComposer } from "@lexical/react/LexicalComposer";
import type { SerializedLexicalNode } from "lexical";
import { memo, type FC } from "react";
import { SharedHistoryContext } from "../lexical/context/SharedHistoryContext";
import { ToolbarContext } from "../lexical/context/ToolbarContext";
import LexicalNodes from "../lexical/nodes/LexicalNodes";
import { TableContext } from "../lexical/plugins/TablePlugin";
import LexicalEditorTheme from "../lexical/themes/LexicalEditorTheme";

import { defaultLanguage } from "../../constants/language";
import type { Language } from "../../types";
import Editor from "./Editor";
import "./index.css";

export type LexicalEditorProps = {
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  language?: Language;
  plugin?: JSX.Element;
};

export type SerializedTagNode = SerializedLexicalNode & {
  tag: string;
};

export const LexicalEditor: FC<LexicalEditorProps> = memo(
  ({ value, onChange, disabled, language = defaultLanguage, plugin }) => {
    const initialConfig = {
      namespace: "Response-editor",
      nodes: [...LexicalNodes],
      onError: (error: Error) => {
        throw error;
      },
      theme: LexicalEditorTheme,
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <TableContext>
            <ToolbarContext>
              <div className="editor-shell">
                <Editor
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  language={language}
                  plugin={plugin}
                />
              </div>
            </ToolbarContext>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    );
  }
);
LexicalEditor.displayName = "LexicalEditor";
