import Script from "next/script";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import type { IAdvizeScriptObject } from "../../types";
import { initIAdvize } from "../helpers";
import { Spinner } from "../ui";

export type Props = {
  currentPageKey: string;
  scripts: IAdvizeScriptObject[];
  shortCode?: string;
};

const ExternalScripts: FC<Props> = ({ currentPageKey, scripts, shortCode }) => {
  const [scriptURL, setScriptURL] = useState<string>();
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);

  // currently, only worked if no more than one script in the page ?
  // If iadvize.sid, it means that we should include the main tag of IAdvize
  const currentScripts = scripts.filter((script) => script.pageKey === currentPageKey);

  const onChatEvent = useCallback(() => {
    setIsScriptLoaded(true);
  }, []);

  useEffect(() => {
    if (currentScripts.length > 0 && currentScripts[0]?.iadvize?.sid) {
      const script = initIAdvize(currentScripts[0]?.iadvize, onChatEvent, shortCode);
      setScriptURL(script);
    }
  }, [currentScripts, shortCode, onChatEvent]);

  return scriptURL ? (
    <>
      {!isScriptLoaded && <Spinner />}
      <Script src={scriptURL} async />
    </>
  ) : null;
};

export default ExternalScripts;
