import { OpenType } from "../../../types/api";
import type { ActionContextualizedRedirection } from "../../../types/common";
import type { DynamicUrls } from "../../../types/scenarioDetails";

type HandleContextualizedRedirectionArgs = {
  branch: string;
  dynamicUrls: DynamicUrls;
} & ActionContextualizedRedirection;

export const handleContextualizedRedirection = ({
  branch,
  location,
  openType,
  dynamicUrls,
}: HandleContextualizedRedirectionArgs) => {
  if (!dynamicUrls) return false;
  const { items, defaultValue } = dynamicUrls;
  const base =
    items.find((item) => item.key === branch)?.url ??
    items.find((item) => item.key === defaultValue)?.url;
  if (!base) return false;
  const url = `${base}${location}`;
  try {
    if (openType === OpenType.SameWindow) window.open(url, "_self");
    else window.open(url, "_blank");
  } catch (_) {
    return false;
  }

  return true;
};
