import { useMemo } from "react";
import { traductions } from "../utils/traductions";

type TranslationVariables = {
  n?: number;
  s?: string;
};

const formatTranslation = (translation: string, variables?: TranslationVariables): string => {
  if (!variables) return translation;
  translation = translation.replace(/%n/g, String(variables.n ?? ""));
  translation = translation.replace(/%s/g, String(variables.s ?? ""));
  return translation;
};

const useTranslations = (language = "fr") => {
  const getTranslation = useMemo(() => {
    return (key: string, variables?: TranslationVariables): string => {
      const keys = key.split(".");
      // don't need to type this
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const trads: any = traductions;
      let translation = trads[language];

      for (const k of keys) {
        if (!translation || typeof translation !== "object" || !(k in translation)) {
          return key;
        }
        translation = translation[k];
      }

      if (typeof translation === "string") {
        return formatTranslation(translation, variables);
      }

      return key;
    };
  }, [language]);

  return getTranslation;
};

export default useTranslations;
