import type { Action } from "../..";
import {
  type ScenarioVersionChildSolutionActionApp,
  type ScenarioVersionChildSolutionActionContextualizedRedirection,
  type ScenarioVersionChildSolutionActionEmail,
  type ScenarioVersionChildSolutionActionInternalFaq,
  type ScenarioVersionChildSolutionActionLiveChatExtended,
  type ScenarioVersionChildSolutionActionPhoneNumber,
  type ScenarioVersionChildSolutionActionPostalMailOrLocation,
  type ScenarioVersionChildSolutionActionStore,
  type ScenarioVersionChildSolutionIFrame,
  type ScenarioVersionChildSolutionSpecific,
  type ScenarioVersionChildSolutionUrl,
  OpenType,
} from "../../../types/api";

export const parseSolutionActionFromApi = (
  actionFromApi: ScenarioVersionChildSolutionSpecific
): Action | null => {
  try {
    const action = {
      iosLink: (actionFromApi as ScenarioVersionChildSolutionActionApp)?.iosLink,
      androidLink: (actionFromApi as ScenarioVersionChildSolutionActionApp)?.androidLink,
      introductionText: (actionFromApi as ScenarioVersionChildSolutionActionApp)?.introductionText,
      url: (actionFromApi as ScenarioVersionChildSolutionUrl)?.url,
      isFastform: (actionFromApi as ScenarioVersionChildSolutionIFrame)?.isFastform,
      playStoreLink: (actionFromApi as ScenarioVersionChildSolutionActionStore)?.playStoreLink,
      appStoreLink: (actionFromApi as ScenarioVersionChildSolutionActionStore)?.appStoreLink,
      to: (actionFromApi as ScenarioVersionChildSolutionActionEmail)?.to,
      subject: (actionFromApi as ScenarioVersionChildSolutionActionEmail)?.subject,
      body: (actionFromApi as ScenarioVersionChildSolutionActionEmail)?.body,
      cc: (actionFromApi as ScenarioVersionChildSolutionActionEmail)?.cc,
      bcc: (actionFromApi as ScenarioVersionChildSolutionActionEmail)?.bcc,
      pageKey: (actionFromApi as ScenarioVersionChildSolutionActionInternalFaq)?.pageKey,
      sid: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)?.sid,
      onlineId: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)?.onlineId,
      offlineId: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)?.offlineId,
      onlineContent: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)
        ?.onlineContent,
      offlineContent: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)
        ?.offlineContent,
      displayByDefault: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)
        ?.displayByDefault,
      code: (actionFromApi as ScenarioVersionChildSolutionActionLiveChatExtended)?.code,
      address1: (actionFromApi as ScenarioVersionChildSolutionActionPostalMailOrLocation)?.address1,
      address2: (actionFromApi as ScenarioVersionChildSolutionActionPostalMailOrLocation)?.address2,
      postalCode: (actionFromApi as ScenarioVersionChildSolutionActionPostalMailOrLocation)
        ?.postalCode,
      city: (actionFromApi as ScenarioVersionChildSolutionActionPostalMailOrLocation)?.city,
      country: (actionFromApi as ScenarioVersionChildSolutionActionPostalMailOrLocation)?.country,
      phoneNumber: (actionFromApi as ScenarioVersionChildSolutionActionPhoneNumber)?.phoneNumber,
      location: (actionFromApi as ScenarioVersionChildSolutionActionContextualizedRedirection)
        ?.location,
      openType:
        (actionFromApi as ScenarioVersionChildSolutionActionContextualizedRedirection)?.openType ||
        OpenType.NewWindow,
      // os: (actionFromApi as ScenarioVersionChildSolutionActionStore)?.os,
    };
    return action;
  } catch (_) {
    // eslint-disable-next-line no-console
    console.error("parseSolutionActionFromApi failed: ", JSON.stringify(actionFromApi));
    return null;
  }
};
