export const traductions = {
  en: {
    floatingToolbar: {
      bold: "Bold",
      code: "Code",
      italic: "Italic",
      link: "Link",
      strikethrough: "Strikethrough",
      underline: "Underline",
    },
    modal: {
      altImage: "Alt text",
      columns: "Columns",
      confirm: "Confirm",
      imageUrl: "Image URL",
      rows: "Rows",
    },
    tableMenu: {
      addColumnHeader: "Add column header",
      addRowHeader: "Add row header",
      columnLeft: "Add %n column%s left",
      columnRight: "Add %n column%s right",
      deleteColumn: "Delete column",
      deleteRow: "Delete row",
      deleteTable: "Delete table",
      rowAbove: "Add %n row%s above",
      rowBelow: "Add %n row%s below",
    },
    toolbar: {
      bold: "Bold",
      bulletList: "Bullet List",
      checkList: "Check List",
      clear: "Clear",
      code: "Code",
      heading: "Heading",
      heading1: "Heading 1",
      heading2: "Heading 2",
      heading3: "Heading 3",
      horizontalRule: "Horizontal Rule",
      image: "Image",
      insertImage: "Insert image",
      insertTable: "Insert table",
      italic: "Italic",
      link: "Link",
      normal: "Normal",
      numberedList: "Numbered List",
      pageBreak: "Page Break",
      quote: "Quote",
      redo: "Redo",
      remove: "Remove",
      strikethrough: "Strikethrough",
      table: "Table",
      underline: "Underline",
      undo: "Undo",
    },
  },
  fr: {
    floatingToolbar: {
      bold: "Gras",
      code: "Code",
      italic: "Italique",
      link: "Lien",
      strikethrough: "Barré",
      underline: "Souligné",
    },
    modal: {
      altImage: "Alt",
      columns: "Colonnes",
      confirm: "Confirmer",
      imageUrl: "URL",
      rows: "Lignes",
    },
    tableMenu: {
      addColumnHeader: "Ajouter un en-tête de colonne",
      addRowHeader: "Ajouter un en-tête de ligne",
      columnLeft: "Ajouter %n colonne%s à gauche",
      columnRight: "Ajouter %n colonne%s à droite",
      deleteColumn: "Supprimer la colonne",
      deleteRow: "Supprimer la ligne",
      deleteTable: "Supprimer le tableau",
      rowAbove: "Ajouter %n ligne%s au-dessus",
      rowBelow: "Ajouter %n ligne%s en dessous",
    },
    toolbar: {
      bold: "Gras",
      bulletList: "Liste à puces",
      checkList: "Checklist",
      clear: "Effacer",
      code: "Code",
      heading: "Titre",
      heading1: "Titre 1",
      heading2: "Titre 2",
      heading3: "Titre 3",
      horizontalRule: "Règle horizontale",
      image: "Image",
      insertImage: "Inserer une image",
      insertTable: "Inserer un tableau",
      italic: "Italique",
      link: "Lien",
      normal: "Normal",
      numberedList: "Liste numérotée",
      pageBreak: "Saut de page",
      quote: "Citation",
      redo: "Rétablir",
      remove: "Supprimer",
      strikethrough: "Barré",
      table: "Tableau",
      underline: "Souligné",
      undo: "Annuler",
    },
  },
};

export type Traductions = typeof traductions;
